import styled from 'styled-components';

export const TextInput = styled.input`
  padding: ${({theme}) => theme.spacing['3']} ${({theme}) => theme.spacing['4']};
  border-radius: ${({theme}) => theme.borderRadius.default};
  border: ${({theme}) => theme.borderWidth['2'] } solid ${({theme}) => theme.input.borderColour['default'] };
  letter-spacing: ${({ theme }) => theme.letterSpacing.wide};
  line-height: ${({theme}) => theme.lineHeight.tight};
  background-color: ${({theme}) => theme.input.backgroundColour};
  color:  ${({theme}) => theme.input.textColour};
  outline: none;
  font-weight: ${({theme}) => theme.fontWeight.bold};
  font-size: 100%;
  
  :focus {
    border-color: ${({theme}) => theme.input.borderColour['focus'] };
  }
`
