import styled, { css } from "styled-components"
import {darken, lighten} from "polished"

export const ErrorCopyStyle = css`
  color: ${({theme}) => theme.errorStyle.textColour};  
`

export const ErrorBackgroundStyle  = css`
  background-color: ${({theme}) => theme.errorStyle.textColour};
`
