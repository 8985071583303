import ReactGA from "react-ga";

import { pending, fulfilled, rejected, gameSelected, swipeToItem, selectGameById, selectUsersGame } from '../features/gamesSearch/gamesSearchSlice'


const reduxEvents = {
  // Computed property
  [pending]: {
    category: 'Search',
    action: 'Executed search',
  },
  [fulfilled]: {
    category: 'API Timing',
    variable: 'apiSearch',
    label: 'get /find/'
  },
  [rejected]: {
    fatal: false
  },
  [gameSelected]: {
    category: 'XPlay results',
    action: 'Game chosen',
    label: 'Selected in XPlay List'
  },
  [swipeToItem]: {
    category: 'XPlay results',
    action: 'Swipe to game',
    label: 'Swipe via carousel'
  }
}


const trackingMiddleware = store => next => action => {

  // console.log('...im the middleware (Tracking)...', action)

  const state = store.getState()

  try {
    switch (action.type) {
      case pending.type:
        let query = formatSearchQuery(action.meta.arg)
        let event = {...reduxEvents[pending], ...query}
        ReactGA.event(event)
        break;
      case fulfilled.type:
        const { reqTime } = action.meta.arg
        const reqExecutionTime = Math.round((Date.now() - reqTime))
        let eventTimings = {...reduxEvents[fulfilled], ...{value: reqExecutionTime}}
        ReactGA.timing(eventTimings);
        break;
      case rejected.type:
        let eventException = {...reduxEvents[fulfilled], ...{description: action.payload,}}
        ReactGA.exception(eventException);
        break;
      case gameSelected.type:
        const game = selectGameById(state, action.payload)
        let eventGame = {...reduxEvents[gameSelected], ...{action: `View: ${game.name}`}}
        ReactGA.event(eventGame)
        break;
      case swipeToItem.type:
        const selectedGame = selectGameById(state, selectUsersGame(state))
        const swipedToGame = selectGameById(state, action.payload)
        let eventSwiped = {...reduxEvents[swipeToItem], ...{action: `View: ${swipedToGame.name}`, label: `Swiped from: ${selectedGame.name}`}}
        ReactGA.event(eventSwiped)
        break;
      default:
        break;
    }
  } catch (e) {
    ReactGA.exception({
      fatal: true,
      description: e.message
    });
    console.log(e.message)
  }

  return next(action);
}

const formatSearchQuery = args => {
  let { search } = args
  let searchTopics = search.split(';')

  if (! Array.isArray(searchTopics)) {
    return {}
  }

  let platforms = searchTopics.shift()

  return {
    action: `${platforms.split(':')[1]}`,
    label: searchTopics.join(' + ')
  }
}

export default trackingMiddleware;