import { get } from "./apiUtils"

const BASE_REST_PATH = '/api/games'


// Time out function.
const timeout = (milliseconds = 1000) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(true);
    }, milliseconds);
  });
}

export const flattenSearch = (acc, curr, idx) => {
  let field = curr.split(':')
  acc[field[0]] = field[1]
  return acc
}

/*
* Games API, send requests to backend.
*
* */
const API =  {
  'getAll': () => get(`${BASE_REST_PATH}`),
  'findById': ({ id }) => get(`${BASE_REST_PATH}/${id}`),
  'find': ({ search, delimiter = ';' }) => {
    const fields = search.split(delimiter)
    const params = fields.reduce(flattenSearch, {})
    return get(`${BASE_REST_PATH}/find`, params )
  },
  'add': () => {},
  'delete': () => {},
}

export const gamesAPI = async (cmd = 'getAll', options) => {
  const response = await API[cmd](options)
  // deliberately delay return response
  await timeout(800);
  const { data } = response
  return data
}
