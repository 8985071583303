import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'

import formReducer from '../features/form/formSlice'
import gameSearchReducer from '../features/gamesSearch/gamesSearchSlice'
import canvasReducer from '../features/canvas/canvasSlice'

import trackingMiddleware from '../middleware'

const middleware = [...getDefaultMiddleware(), trackingMiddleware]

export default configureStore({
  reducer: {
    form: formReducer,
    gamesSearch: gameSearchReducer,
    canvas: canvasReducer
  },
  middleware
})