import styled, { css } from 'styled-components'

export const SvgBase = styled.svg.attrs({
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg',
  xmlnsXlink: 'http://www.w3.org/1999/xlink',
})``

const CloseSvgIcon = styled(SvgBase)`
  display: inline-block;
  width: ${({theme}) => theme.spacing['10']};
  height: ${({theme}) => theme.spacing['10']};
  stroke-width: 2;
  stroke: currentColor;
  fill: currentColor;
  
  font-size:  ${({theme}) => theme.fontSize['3xl']};
  color: ${({theme}) => theme.textColour};
`

export const CloseIcon  = ({ className }) => (
  <CloseSvgIcon className={`${className} icon-close`} viewBox="0 0 53.7 53.7" >
    <path
      fill="currentColor"
      d='M35.6 34.4L28 26.8l7.6-7.6c.2-.2.2-.5 0-.7l-.5-.5c-.2-.2-.5-.2-.7 0l-7.6 7.6-7.5-7.6c-.2-.2-.5-.2-.7 0l-.6.6c-.2.2-.2.5 0 .7l7.6 7.6-7.6 7.5c-.2.2-.2.5 0 .7l.5.5c.2.2.5.2.7 0l7.6-7.6 7.6 7.6c.2.2.5.2.7 0l.5-.5c.2-.2.2-.5 0-.7z' />
  </CloseSvgIcon>
)

const MagnifyingGlassSvg = styled(SvgBase)`
  display: inline-block;
  width: ${({theme}) => theme.spacing['4']};
  height: ${({theme}) => theme.spacing['3']};
  stroke-width: 2;
  stroke: currentColor;
  fill: none;
  color: #fff;
`

export const MagnifyingGlassIcon  = ({ className }) => (
  <MagnifyingGlassSvg className={`${className} icon-search`} fill="none" focusable={false} viewBox="0 0 22.865 22.865" >
    <circle transform="rotate(-45 8.897 8.889)" cx="8.897" cy="8.889" r="8.111" />
    <path d="M14.632 14.624l7.457 7.457" />
  </MagnifyingGlassSvg>
)

const TickSVGIcon = styled(SvgBase)`
  display: inline-block;
  width: 10px;
  height: 10px;
  stroke-width: 4;
  stroke: currentColor;
  fill: none;
`

export const TickIcon = ({ className }) => (
  <TickSVGIcon className={`${className} icon-tick`} viewBox="0 0 24 24" stroke="currentColor" fill="none">
    <path strokeLinecap="round" strokeLinejoin="round"  d="M5 13l4 4L19 7" />
  </TickSVGIcon>
)

const ChevronSVGIcon = styled(SvgBase)`
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 2;
  stroke: currentColor;
`

export const ChevronRight = ({ className }) => (
  <ChevronSVGIcon className={`${className} icon-chevron-right`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="currentColor" fill="none">
    <path strokeLinecap="round" strokeLinejoin="round"  d="M9 5l7 7-7 7" />
  </ChevronSVGIcon>
)

export const ChevronUp = ({ className }) => (
  <ChevronSVGIcon className={`${className} icon-chevron-up`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="currentColor" fill="none">
    <path strokeLinecap="round" strokeLinejoin="round"  d="M5 15l7-7 7 7" />
  </ChevronSVGIcon>
)

export const ChevronDown = ({ className }) => (
  <ChevronSVGIcon className={`${className} icon-chevron-down`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="currentColor" fill="none">
    <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
  </ChevronSVGIcon>
)

export const InlineIconBase = css`
  display: inline-flex;
  align-items: center;
  gap: ${({theme}) => theme.spacing['1']};
  text-decoration: underline;
`

export const InformationCircle = ({ className }) => (
  <ChevronSVGIcon className={`${className} icon-information-circle`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="currentColor" fill="none">
    <path strokeLinecap="round" strokeLinejoin="round" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
  </ChevronSVGIcon>
)

const AdjustmentsSVGIcon = styled(SvgBase)`
  display: inline-block;
  width: ${({theme}) => theme.spacing['4']};
  height: ${({theme}) => theme.spacing['3']};
  stroke-width: 2;
  color: #fff;
`

export const Adjustments = ({ className }) => (
  <AdjustmentsSVGIcon className={`${className} icon-adjustments`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" stroke="currentColor" fill="none">
    <path
      d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z"/>
  </AdjustmentsSVGIcon>
)
