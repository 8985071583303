import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const useGAWithRouter = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);
  const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING || '';

  useEffect(() => {
    if (GA_TRACKING_ID) {
      let options = {}
      // If gtag.js detects that you're running a server locally (e.g. localhost),
      // it automatically sets the cookie_domain to 'none'.
      // https://developers.google.com/analytics/devguides/collection/gtagjs/cookies-user-id
      if (window.location.href.includes("localhost")) {
        options.debug = true
      }

      ReactGA.initialize(GA_TRACKING_ID, options);
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [initialized, location]);
};

export const sendToAnalytics = ({ id, name, value }) => {
  ReactGA.event({
    category: 'Web Vitals',
    action: name,
    value: Math.round(name === 'CLS' ? value * 1000 : value),
    label: id,
    nonInteraction: true
  });
}

export default useGAWithRouter;