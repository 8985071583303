const baseStyles = {
  font: {
    sans: 'system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    serif: 'Arvo, sans',
    monospace: 'source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace',
    highlight: '"Nunito", "Open Sans", Arial, sans-serif',
  },
  breakpoint: {
    sm: 'min-width: 37.5rem',
    md: 'min-width: 50rem',
    smWidth: '37.5rem',
    mdWidth: '50rem'
  },
  fontSize: {
    xs: '0.75rem',
    sm: '0.875rem',
    base: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '4rem',
  },
  fontWeight: {
    hairline: '100',
    thin: '200',
    light: '300',
    normal: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
    extrabold: '800',
    black: '900',
  },
  borderRadius: {
    none: '0',
    sm: '0.125rem',
    default: '0.25rem',
    lg: '0.5rem',
    full: '9999px',
  },
  borderWidth: {
    default: '1px',
    '0': '0',
    '2': '2px',
    '4': '4px',
    '8': '8px',
  },
  boxShadow: {
    default: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    xl: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
    inner: 'inset 0 6px 8px 0 rgba(0, 0, 0, 0.1)',
    outline: '0 0 0 3px rgba(66, 153, 225, 0.5)',
    none: 'none',
  },
  letterSpacing: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },
  spacing: {
    px: '1px',
    '0': '0',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '8': '2rem',
    '10': '2.5rem',
    '12': '3rem',
    '16': '4rem',
    '20': '5rem',
    '24': '6rem',
    '32': '8rem',
    '40': '10rem',
    '48': '12rem',
    '56': '14rem',
    '64': '16rem',
  },
  lineHeight: {
    none: '1',
    tight: '1.25',
    snug: '1.375',
    normal: '1.5',
    relaxed: '1.625',
    loose: '2',
  },
  opacity: {
    '0': '0',
    '25': '0.25',
    '50': '0.5',
    '75': '0.75',
    '100': '1',
  },
  errorStyle: {
    textColour: '#ff5252',
    backgroundColour: '#ffb74d',
    borderColour: '#ffb74d',
  },
  animationTime:{
    default: '300ms'
  }
}

export const light = {
  ...baseStyles,
  body: '#f2f2f2',
  bodyHighlight: '#d3d4d6',
  bodyElevate: '#d3d4d6',
  textColour: '#404040',
  color: {
    primary: '#6CFE84',
    secondary: '#36B37E',
    tertiary: '#6f42c1',
    quaternary: '#1c62b9'
  },
  button: {
    submitBackground: '#ffc400',
  },
  input: {
    borderColour: {
      'default': '#ffffff',
      'focus': 'inherit'
    },
    textColour: 'inherit',
    backgroundColour: 'inherit'
  },
  popup: {
    background: '#dddddd'
  }
}

export const dark = {
  ...baseStyles,
  body: '#121212',
  bodyHighlight: '#212121',
  bodyElevate: '#534F50',
  textColour: '#f6f7f0',
  textAccentedColour: '#121212',
  color: {
    primary: '#4dd0e1',
    secondary: '#7e57c2',
    tertiary: '#1c62b9',
    quaternary: '#f06292',
  },
  button: {
    submitBackground: '#ffc400',
  },
  input: {
    borderColour: {
      'default': '#303030',
      'focus': '#1c62b9'
    },
    textColour: '#aaa',
    backgroundColour: '#121212'
  },
  popup: {
    background: '#212121'
  },
  errorStyle: {
    textColour: '#ff5252'
  }
}