import { createSlice, createAction } from '@reduxjs/toolkit'

const formUpdateComplete = createAction('form/formUpdateComplete')

const initialState = {
  platforms: '',
  genres: '',
  mode: 'Multiplayer'
}


const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    updateFormSelection: (state, action) => {
      return {...state, ...action.payload }
    }
  }
})

const { actions, reducer } = formSlice

const { updateFormSelection }  = actions

export const formSelector = state => state.form

// AsyncThunk as a normal Function (not createAsyncThunk action creator)
export const broadcastFormSelection = formState => {
  return ( dispatch, getState ) => {
    dispatch(updateFormSelection(formState))
    const updatedState = formSelector(getState())
    // Let other reducers know form has updated.
    dispatch(formUpdateComplete(updatedState))
  }

}


export default reducer