import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'


const GlobalStyles = createGlobalStyle`
  /* Browser reset */
  ${reset}
 
  
  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    width: 100%;
    height: 100%;
    
    /* Fix for scroll chaining on iPhone10 */
    overflow: hidden;
    position: relative;
    
    --colour-body: ${({theme}) => theme.body};
    --colour-body-elevate: ${({theme}) => theme.bodyElevate};
    --colour-primary: ${({theme}) => theme.color.primary};
    --colour-secondary: ${({theme}) => theme.color.secondary};
    --colour-tertiary: ${({theme}) => theme.color.tertiary};
    --colour-quaternary: ${({theme}) => theme.color.quaternary};
    
  }
  
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  
  /* Base theme settings */
  body {
    font-family: ${({theme}) => theme.font.sans};
    font-size: ${({theme}) => theme.fontSize.base};
    background-color : ${({theme}) =>  theme.body};
    color : ${({theme}) =>  theme.textColour};
    line-height: ${({theme}) =>  theme.lineHeight.tight};
    letter-spacing: ${({ theme }) => theme.letterSpacing.wide};
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;    
    width: 100%;
    height: 100%;
    
    a {
      color : ${({theme}) =>  theme.textColour};
      text-decoration: none;
    }
    
    /* Fix for scroll chaining on iPhone10 */
    overflow: auto;
    position: relative;
  }
  
  div#root {
    width: 100%;
    height: 100%;
  }
`

export default GlobalStyles;

