import styled, {css} from "styled-components"
import { darken } from "polished"
import {InlineIconBase} from "./SVGIcon";


export const BaseButton = css`
   font-family: ${({theme}) => theme.font.monospace};
   color: ${({theme, accent }) => accent ? theme.textAccentedColour : theme.textColour };
   display: inline-block;
   font-weight: ${({theme}) => theme.fontWeight.bold };
   font-size: ${({theme, sm}) => sm ? theme.fontSize.xs : theme.fontSize.sm };
   line-height: ${({theme}) => theme.lineHeight.normal };
   text-align: center;
   text-decoration: none;
   vertical-align: middle;
   user-select: none;
   outline: none;
   cursor: ${({disabled}) => disabled ? 'initial' : 'auto'};
   
   @media ( ${({theme}) => theme.breakpoint['sm']} ) {
    cursor: ${({disabled}) => disabled ? 'initial' : 'pointer'};
   }
`

export const Button = styled.button`
   
   ${BaseButton}
   
   background-color: ${({ active, theme}) => active ?  darken(0.05, theme.color.primary) : theme.color.primary };
   border-radius: ${({theme}) => theme.borderRadius.sm };
   border: ${({theme}) => theme.borderWidth.default} solid transparent;
   border-bottom: ${({theme}) => theme.borderWidth['4']} solid ${({theme}) => darken(0.2, theme.color.primary)};
   padding: ${({theme, sm}) => sm ? `.3rem .5rem` : `.375rem .45rem` };
   opacity: ${({theme, disabled}) => disabled ? theme.opacity['50'] : 'inherit'};
   box-shadow: ${({ active, theme}) => active ? theme.boxShadow.inner : 'none' };
   
   ${({deEmphasize, theme}) => deEmphasize && css`
    opacity: ${theme.opacity[75]};
   `}
   
   /* Push text down when clicked/touched, gives a bounce effect */
   &:active {
    padding-top: ${({sm}) => sm ? `.4rem` : `.5rem`};
    padding-bottom: ${({sm}) => sm ? `.15rem` : `.25rem`};
   }
   
   > .icon-tick {
    display: none;
    margin-left: ${({theme}) => `${theme.spacing['1']}` };
   }

   ${({active}) => active &&`
      > .icon-tick {
        display: inline;
      }
   `}  
   
`

export const NakedButton = styled.button`
  
  ${BaseButton}
  
  background-color: transparent;
  border-style: none;
  border-width: 0px;
  padding: 0px;
`

export const PlainButton = styled(Button)`
  background-color: transparent;
  border-style: none;
  border-width: 0px;  
`

export const ShowHideButton = styled(PlainButton)`  
  ${InlineIconBase}
  padding: 0;
  :active {
    padding: 0;
  }
`

export const SecondaryButton = styled(Button)`
  border-radius: ${({theme}) => theme.borderRadius.default };
  background-color: ${({ active, theme}) => active ? darken(0.07, theme.color.secondary) : theme.color.secondary };
  border-bottom: ${({theme}) => theme.borderWidth['4']} solid ${({theme}) => darken(0.2, theme.color.secondary)};
`

export const TertiaryButton = styled(Button)`
  border-radius: ${({theme}) => theme.borderRadius.default };
  background-color: ${({ active, theme}) => active ? darken(0.07, theme.color.tertiary) : theme.color.tertiary };
  border-bottom: ${({theme}) => theme.borderWidth['4']} solid ${({theme}) => darken(0.2, theme.color.tertiary)};
`

export const SubmitButton = styled(Button)`
  background-color: ${({theme}) => theme.button.submitBackground };
  border-bottom: ${({theme}) => theme.borderWidth['4'] } solid ${({theme}) => darken(0.1, theme.button.submitBackground)};
  font-size: ${({theme}) => theme.fontSize.base };
`

export const PlainSubmit = styled(Button)`
  background-color: transparent;
  border-color: ${({theme}) => theme.textColour };
  font-size: ${({theme}) => theme.fontSize.base };
`

export const ButtonRadio = styled(Button)` 
  label input[type^="radio"]:checked + & {
    box-shadow: ${({theme}) => theme.boxShadow.inner};
    background-color: ${({theme}) => darken(0.2, theme.color.primary) };
    border-color: ${({theme}) => darken(0.2, theme.color.primary) };
  }    
`

export const ButtonCheckbox = styled(Button)`  
  label input[type^="checkbox"]:checked + & {
    box-shadow: ${({theme}) => theme.boxShadow.inner};
    background-color: ${({theme}) => darken(0.2, theme.color.primary) };
    border-color: ${({theme}) => darken(0.2, theme.color.primary) };
  }    
`