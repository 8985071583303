import styled, { css } from "styled-components"

export const Labels = styled.label`
 color: ${({theme}) => theme.textColour || 'black'};
`

export const LabelWithInput = styled(Labels)`
  ${ 
    css`
      input[type="radio"], 
      input[type="checkbox"] {
        position: absolute;
        clip: rect(0,0,0,0);
        pointer-events: none;
      }
    ` 
  }
`
