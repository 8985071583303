import styled, { keyframes } from "styled-components"

const LoaderContainer = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({theme}) => theme.spacing['4']};
  width: 100%;
  height: 100%;
`;

const rotate = keyframes`
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
`

const LoaderAnimation = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  
  div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: ${rotate} 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  
  div:nth-child(2) {
    animation-delay: -0.5s;
  }
`

const Loader = () => {
  return (
    <>
      <LoaderContainer>
        <LoaderAnimation>
          <div></div>
          <div></div>
        </LoaderAnimation>
      </LoaderContainer>
    </>
  )
}

export default Loader;
