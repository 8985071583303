import styled, { css } from "styled-components"
import {darken, lighten} from "polished";

export const P = styled.p`
  font-size: ${({theme, sm}) => sm ? theme.fontSize.xs : theme.fontSize.base};
  margin-top: ${({ theme, noMargin }) => !noMargin ? theme.spacing[2] : 0};
  opacity: ${({ theme, deEmphasize }) => deEmphasize ? theme.opacity[75] : 1};
  
  &.breaker {
    border-top: ${({theme}) => `${theme.borderWidth['default']} solid ${theme.bodyElevate}` };    
  }
  
  ${({highlight}) => highlight && css`
    font-weight: ${({theme}) => theme.fontWeight['semibold']};
  `}
  
  ${({wordBreak}) => wordBreak && css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
  
  ${({truncate}) => truncate && css`
      /* Number of lines x default line height */     
      // height: ${({theme}) => (4 * theme.lineHeight['tight'])}em;
      // position: relative;
      // text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;  
      overflow: hidden;
      position: relative;
      
      &:after {
        content:'';
        width:100%;
        height: 50%;  
        position:absolute;
        left:0;
        bottom:0;
        background: linear-gradient(rgba(0,0,0,0), ${({theme}) => theme.popup.background});
      }
    `  
  }
`

export const UL = styled.ul`
  font-size: ${({theme, sm}) => sm ? theme.fontSize.xs : theme.fontSize.base};
  padding: ${({theme, fancy}) => fancy ? `${theme.spacing['1']} ${theme.spacing['2']}` : 0};
  border-left: ${({theme, fancy}) =>  fancy ? `${theme.borderWidth['2']} solid ${theme.color.tertiary}` : ''};
  margin-top: ${({ theme, noMargin }) => !noMargin ? theme.spacing[2] : 0}; 
  opacity: ${({ theme, deEmphasize }) => deEmphasize ? theme.opacity[75] : 1};
  overflow: ${({wordBreak}) => wordBreak ? 'hidden' : 'visible'};
  
  li {
    margin-bottom: ${({theme, fancy}) => fancy ? theme.spacing['1'] : '0'};
    
    ${({wordBreak}) => wordBreak && css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
  }  
  
  
    /* If prop passed with horizontal direction  */
  ${({horizontal}) => horizontal && `  
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    flex-wrap: wrap;      
  `};
  
  ${({dots}) => dots && css`
    > li {
      position: relative;
      flex: 0 0 auto; 
      margin-left: ${({theme}) => theme.spacing[6]};
      
      :before {
        left: ${({theme}) => `-${theme.spacing[4]}` };
      }
    }
    
    > li  {
      :before {
        content: "";
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 100%;
        top: 5px;
        margin: auto;
        background: var(--colour-quaternary);
      }
    } 
  `}
  
  /* If dots are on, as well as being horizontal */
  ${({dots, horizontal}) => (dots && horizontal) && css`
    > li {
      /* Center align dot */
      :before {
        top: 0;
        bottom: 0;
      }
    
      :first-child {
        margin: 0;
        :before {
          content: none;
        }
      } 
    }
  `}
  
`

export const TextStyle = styled.span`
  font-size: ${({theme, sm}) => sm ? theme.fontSize.xs : theme.fontSize.base};
  font-weight: ${({theme, bold}) => bold ? theme.fontWeight.medium : theme.fontWeight.normal };
`

export const ParaPills = styled(P)`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center ;
   
  gap: ${({theme}) => theme.spacing['1']};
  
  /* If border arg passed */
  ${({theme, border}) => border && css`
      border: solid ${theme.borderWidth['default']} ${darken(0.18, theme.textColour)};
      padding: ${theme.spacing['1']};
      border-radius: ${({theme}) => theme.borderRadius['default']};
    `
  }; 
  
  /* If border & accent arg passed */
  ${({theme, border, accent}) => (accent & border) && css`
    border-color: ${theme.color.quaternary};
  `
  };
  
  > span {
    flex: 0 0 auto;  
    color: ${({theme, darkenText}) => darkenText? darken(0.1, theme.color.quaternary) : theme.color.quaternary};
    font-weight: ${({theme, bold}) => bold ? theme.fontWeight.medium : theme.fontWeight.normal };
    padding: ${({theme}) => theme.spacing['1']} ${({theme}) => theme.spacing['2']};
    background-color: ${({theme}) => theme.body};
    border: solid ${({theme}) => theme.borderWidth['default']} ;
    border-radius: ${({theme}) => theme.borderRadius['default']};
    border-color: ${({theme, accent}) => accent ? theme.color.quaternary : theme.body};
    position: relative;
    opacity: ${({ theme, deEmphasize }) => deEmphasize ? .9 : theme.opacity['100']}; 
    
    &.chained {
      ${({theme}) => css`
         border-color: ${theme.color.quaternary};
         opacity: ${({ theme}) => theme.opacity['100']};
        `
      }
    }
    
    &.selected-platform {
      ${({theme, accent}) => css`
          background-color: ${theme.color.quaternary};
          color: ${theme.textAccentedColour};
        `
      }
    }
  }
`