import { createSlice }  from '@reduxjs/toolkit'

const canvasSlice = createSlice({
  name: 'canvas',
  initialState: { popUps:{} },
  reducers: {
    togglePopUpId: (state, action) => {
      const { popupId, status } = action.payload
      state.popUps[popupId] = status
    }
  }
})

const { actions, reducer } = canvasSlice

export const {togglePopUpId } = actions

export const getCanvasPopups = (state, popUpId) => state.canvas.popUps[popUpId] ? state.canvas.popUps[popUpId] : false

export default reducer