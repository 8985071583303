import {lazy, Suspense} from 'react'
import {Route, Switch, Link} from 'react-router-dom'
import { Helmet } from "react-helmet";
import useGAWithRouter from './useGAWithRouter'

import styled from "styled-components"

import Loader from './features/gamesSearch/Loader'

import {
  CenteredSection,
  H2,
  Container,
  InlineIconBase,
  InformationCircle,
  PlainButton,
  AppContainer
} from './theme/styled-components'

//Lazy Loading
const Home = lazy(() => import('./pages/Home'))
const About = lazy(() => import('./pages/About'))
const Contact = lazy(() => import('./pages/Contact'))
const NotFound = lazy(() => import('./pages/NotFound'))

const H2Extended = styled(H2)`
  font-family: ${({theme}) => (theme.font.sans)};
  font-size: ${({theme}) => theme.fontSize['2xl']};
  font-weight: ${({theme}) => theme.fontWeight.thin};
  padding: ${({theme}) => theme.spacing['3']} 0;
  
  em {
    font-weight: ${({theme}) => theme.fontWeight.extrabold};
  }  
`

const ContainerExtended = styled(Container)`
  /* Special placement of title */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  
  > {
    &:first-child { 
      margin-right: auto;
    }
    
    span {
      padding-left: ${({theme}) => theme.spacing['2']};
    }
  }
`

const TitleRibbon = styled.div`
  background-color: ${({theme}) => theme.bodyHighlight};
`

const PlainButtonExtended = styled(PlainButton)`
  
  ${InlineIconBase};
  /* Removing defaults */
  padding: 0;
  line-height: 0;
  /* Manually setting icon size */
  svg{
    font-size: 1.6em;
  } 
`

function App() {

  useGAWithRouter();

  return (
    <Suspense fallback={Loader()}>
      <Helmet titleTemplate="%s - XPlay Gaming" defaultTitle="XPlay Gaming -  List of the latest cross playable games">
        <meta name="description" content="Quickly find games to cross play with your friends, across Playstation, Xbox, Nintendo and PC" />
        <meta property="og:description" content="Quickly find games to cross play with your friends, across Playstation, Xbox, Nintendo and PC" />
      </Helmet>
      <AppContainer>
        <TitleRibbon>
          <CenteredSection>
            <ContainerExtended as="div">
              <H2Extended noMargin>
                <Link to="/"><em>XPlay</em>Gaming</Link>
              </H2Extended>
              <PlainButtonExtended deEmphasize as="span">
                <Link to="/about">About</Link>
              </PlainButtonExtended>
              <PlainButtonExtended deEmphasize as="span">
                <Link to="/contact">Contact</Link>
              </PlainButtonExtended>
            </ContainerExtended>
          </CenteredSection>
        </TitleRibbon>
        <CenteredSection>
          <Switch>
            <Route exact path="/about">
              <About/>
            </Route>
            <Route exact path="/contact">
              <Contact/>
            </Route>
            <Route exact path={["/search/:platforms/:genres/:mode", "/"]}>
              <Home/>
            </Route>
            <Route path="*">
              <NotFound/>
            </Route>
          </Switch>
        </CenteredSection>
      </AppContainer>
    </Suspense>
  );
}

export default App;
