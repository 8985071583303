import styled, { css } from "styled-components"
import { ErrorBackgroundStyle } from './Errors'

export const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto;
  
  > * {
    flex: 0 0 auto;
    
   &:last-child {
    flex: 0 1 auto;
   }
  }
`

const ContainerBaseStyle = css`
  display: block;
  /* On Mobile, 100% of parent */
  height: 100%;
  width: 100vw;
  padding: 0 ${({theme}) => theme.spacing['6']};
  padding-bottom: ${({theme, trim}) => trim ? theme.spacing['6']: 0};

`

export const Container = styled.section`
  
  ${ContainerBaseStyle};
 
  @media (${({theme}) => theme.breakpoint['sm']}) {
    height: auto;
    width: ${({theme}) => theme.breakpoint['smWidth']};
  }
  
  @media (${({theme}) => theme.breakpoint['md']}) {
    width: ${({theme}) => theme.breakpoint['mdWidth']};
  }
`

export const CenteredSection = styled.section`
  display: flex;
  /* On Mobile, 100% of parent */
  height: 100%;
  flex-direction: column;
  @media (${({theme}) => theme.breakpoint['sm']}) {
    height: auto;
    justify-content: center;
    align-items: center;
  }
`

export const ContentCopy = styled.div`
  
  padding: 0 ${({theme}) => theme.spacing['6']};
 
  p {
    margin: ${({theme}) => theme.spacing['6']} 0;
    line-height: ${({theme}) => theme.lineHeight['relaxed']};     
  }
  
  p + p.reverse {
    margin-top: ${({theme}) => `-${theme.spacing['6']}`}
  }
  
  ul {
    margin: ${({theme}) => theme.spacing['6']} 0;
  }
  div {
    &.breaker {
      border-top: 1px solid ${({theme}) => theme.bodyElevate };
      margin-top: ${({ theme }) => theme.spacing[6]};
      padding-top: ${({ theme }) => theme.spacing[6]};    
    }
  }
  
  h2 + p,
  h3 + p {
    margin-top: ${({theme}) =>  theme.spacing['4'] };
    
  }
  
  @media (${({theme}) => theme.breakpoint['md']}) {
    padding: 0 ${({theme}) => theme.spacing['10']};
    
    > p,
    > ul,
    > div,
    > h2,
    > h3 {
      width: 600px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`

export const HeaderContainer = styled.header`
  padding: ${({theme}) => `${theme.spacing['6']} ${theme.spacing['6']}` };
  border-bottom: ${({theme}) => `${theme.borderWidth['default']} solid ${theme.bodyElevate}` };
`

export const GridBase = css`
  display: grid;
  gap: ${({theme}) => theme.spacing['1'] };
`

export const GridButtonContainer = styled.div`
  ${GridBase}
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
`

export const SectionPopup = styled.section`
  background-color:  ${({theme}) => theme.popup.background };
`

export const ErrorContainer = styled.section`
  ${ErrorBackgroundStyle};
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${({theme}) => theme.spacing['2']} 0;
  gap: ${({theme}) => theme.spacing['2']};
  
  .error-display{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
  }
`
