import {createSlice, createAsyncThunk, createAction} from '@reduxjs/toolkit'
import {createSelector} from "reselect";

import {gamesAPI} from '../../api/gamesSearchApi'


// Async Thunk Action Creator
export const fetchGamesList = createAsyncThunk(
  'gameSearch/fetchGamesList',
  async (args, {getState, rejectWithValue}) => {
    const { search } = args
    try {
      const { searches } = getState().gamesSearch
      // @ToDo: use condition:false option instead in payloadCreator?
      // No need to execute network request if search is cached.
      if (searches[search]?.length > 0) {
        // Returning empty Promise as search results already exist.
        return new Promise((res) => res({data:[]}))
      }

      // Network fetch (find games) to API.
      //console.log('Executing API request....', search)
      const response = await gamesAPI('find', {search})
      //console.log('Finished executing API request.', response)
      return response

    } catch (e) {
      return rejectWithValue(e)
    }
  }
)

const flattenFormState = values => {
  return Object
    .keys(values)
    .reduce((acc, formProp) => {
      let chosenValues = (Array.isArray(values[formProp])) ? values[formProp].join(',') : values[formProp]
      return acc.concat(formProp + ":" + chosenValues);
    }, []);
}

const gamesSearchSlice = createSlice({
  'name': 'gameSearch',
  initialState: {entities: [], searches: {}, status: 'idle', error: null, search: null, gameSelected: null},
  reducers: {
    searchKey: (state, action) => {
      state.search = flattenFormState(action.payload).join(';')
    },
    gameSelected: (state, action) => {
      state.gameSelected = action.payload
    },
    resetBackendError: (state) => {
      state.status = 'idle'
      state.error = ''
      state.search = null
    }
  },
  extraReducers: {
    [fetchGamesList.pending]: (state, action) => {
      if (state.status === 'idle') {
        state.status = 'pending'
        // App was idle but now is processing data to an api...'
      }
    },
    [fetchGamesList.fulfilled]: (state, action) => {
      if (state.status === 'pending') {
        const {search, entities} = state
        const {data} = action.payload

        // Compare Array 1 with Array 2, provide true if ids match
        const idExists = (listOfItems, newItem) => listOfItems.some(item => item.id === newItem?.id)
        // Only add new games to list found from service call. Will not re-add old one.
        const newEntities = data.filter(newItem => (idExists(entities, newItem) === false))

        state.entities = [...data, ...entities]
        state.searches[search] = data.length > 0 ? data.map(game => game.id)
          : state.searches?.[search] ? state.searches[search]
          : []
        state.status = 'idle'
      }
    },
    [fetchGamesList.rejected]: (state, action) => {
      if (state.status === 'pending') {
        const {meta, payload, error} = action
        // If aborted, we want to reset to idle, otherwise failed.
        state.status = meta?.aborted ? 'idle' : 'failed'
        state.error = payload?.length > 0 ? payload : error.message
      }
    }
  }

})

const {actions, reducer} = gamesSearchSlice

export const {searchKey, gameSelected, resetBackendError} = actions

export const { pending, fulfilled, rejected } = fetchGamesList

export const swipeToItem = createAction('gameSearch/swipeToItem');

export const selectAllGamesStored = state => state.gamesSearch.entities

export const selectCurrentGamesSearch = state => state.gamesSearch.search

export const gamesApiStatus = state => state.gamesSearch.status

export const gamesApiError = state => state.gamesSearch.error

export const selectUsersGame = state => state.gamesSearch.gameSelected

export const selectGameById = (state, userGameId) => state.gamesSearch.entities.find(game => game.id === userGameId)

// Memoized list, return stored games based on key (search string is the key)

export const selectCurrentGamesList = createSelector(
  state => state.gamesSearch.searches,
  selectAllGamesStored,
  selectCurrentGamesSearch,
  (searches, allGames, search) => {
    if (searches?.hasOwnProperty(search)) {
      return searches[search].map(id => {
        return allGames.find(game => game.id === id)
      })
    }

    return []
  }
)

export default reducer