import styled from 'styled-components';

export const H2 = styled.h2`
  font-family: ${({theme}) => (theme.font.highlight)};
  margin-top: ${({ theme, noMargin }) => !noMargin ? theme.spacing[4] : 0};
  font-size: ${({theme}) => theme.fontSize['2xl']};
  line-height: ${({ theme }) => theme.lineHeight.none};
  letter-spacing: ${({ theme }) => theme.letterSpacing.wide};
  font-weight: ${({theme}) => theme.fontWeight.semibold };
  cursor:  ${({ onClick}) => onClick ? 'pointer' : 'auto'};
`

export const H3 = styled.h3`
  font-family: ${({theme}) => (theme.font.highlight)};
  margin-top: ${({ theme, noMargin }) => !noMargin ? theme.spacing[4] : 0};
  font-size: ${({theme}) => theme.fontSize['xl']};
  line-height: ${({ theme }) => theme.lineHeight.none};
  letter-spacing: ${({ theme }) => theme.letterSpacing.wide};
  font-weight: ${({theme}) => theme.fontWeight.semibold };
  cursor:  ${({ onClick}) => onClick ? 'pointer' : 'auto'};
 
`

export const H5 = styled.h5`
  font-family: ${({theme}) => (theme.font.highlight)};
  margin-top: ${({ theme, noMargin }) => !noMargin ? theme.spacing[4] : 0};
  font-size: ${({theme}) => theme.fontSize['base']};
  line-height: ${({ theme }) => theme.lineHeight.none};
  letter-spacing: ${({ theme }) => theme.letterSpacing.wide};
  font-weight: ${({theme}) => theme.fontWeight.semibold };
  cursor:  ${({ onClick}) => onClick ? 'pointer' : 'auto'};
`