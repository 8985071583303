// apiUtils.js
import axios from 'axios';
import handleError from './handleError';

const getHeaders = () => {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  };
};
// HTTP GET Request - Returns Resolved or Rejected Promise
export const get = (path, params = {}, headers = getHeaders()) => {
  return new Promise((resolve, reject) => {
    axios.get(`${path}`, { params, headers })
      .then(response => { resolve(response) })
      .catch(error => { reject(handleError(error)) });
  });
};
// HTTP PATCH Request - Returns Resolved or Rejected Promise
export const patch = (path, data, headers = getHeaders()) => {
  return new Promise((resolve, reject) => {
    axios.patch(`${path}`, { data }, { headers })
      .then(response => { resolve(response) })
      .catch(error => { reject(handleError(error)) });
  });
};
// HTTP POST Request - Returns Resolved or Rejected Promise
export const post = (path, data, headers = getHeaders()) => {
  return new Promise((resolve, reject) => {
    axios.post(`${path}`, data, { headers })
      .then(response => { resolve(response) })
      .catch(error => { reject(handleError(error)) });
  });
};
// HTTP DELETE Request - Returns Resolved or Rejected Promise
export const del = (path, headers = getHeaders()) => {
  return new Promise((resolve, reject) => {
    axios.delete(`${path}`, { headers })
      .then(response => { resolve(response) })
      .catch(error => { reject(handleError(error)) });
  });
};